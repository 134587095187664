.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 3px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 5px;
}

.tabs {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tabs .tab-pane {
  margin-left: 20px;
}

.tabs h3 {
  font-size: 20px;
  margin-top: 10px;
}

.tabs a {
  font-size: 15px;
  font-family: OpenSans, sans-serif;
  font-weight: 700;
  color: #fff;
}

.sidetab li {
  background-color: #333;
  margin-top: 1px;
  text-align: center;
  height: 50px;
  width: auto;
  padding-top: 13px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.tabs li.active {
  background-color: #0085c3;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 7px;
    cursor: pointer;
    margin: 2px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
