
.myguestnav {
    height: 6%;
    width: 100%;
    background: #0F2027;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    position: relative;
  }
  
  .myguestnav > .nav-header {
    display: inline;
  }
  
  .myguestnav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  
  .myguestnav > .nav-btn {
    display: none;
  }
  
  .myguestnav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
  }
  
  .myguestnav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }
  
  .myguestnav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .myguestnav > #nav-check {
    display: none;
  }
  
  @media (min-width:610px) {
    .nav-mobile {
      display: none;
    }
  }
  @media (max-width:600px) {
   
  .nav-mobile {
    background: #446cb3;
    color: #fff;
    padding: 0;
    top: 0;
    position:absolute;
    cursor: auto;
    font-size: 18px;
    list-style-type: none;
    box-shadow: 0 5px 5px -5px #333;
 }
  .nav-mobile:after {
    content: "";
    display: table;
    clear: both;
 }
  .nav-mobile svg {
    background: #343A40;
    height: 45px;
    width: 65px;
    padding: 9px;
    left: 0;
    position: fixed;
 }
  .nav-mobile svg path {
    fill: #fff;
 }
  .nav-mobile svg.icon-close {
    display: none;
    padding: 15px;
 }
  .nav-mobile li {
    width: 100%;
    height: 45px;
    line-height: 46px;
    text-align: center;
    float: left;
 }
  .nav-mobile li .mlink {
    display: block;
    color: #fff;
    width: 100%;
    height: 100%;
    text-decoration: none;
 }
  .nav-mobile .menu-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    display: block;
 }
  .nav-mobile .menu-button:after {
    opacity: 0;
    top: 45px;
    content: "";
    width: 100vw;
    display: block;
    position: fixed;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
    transition-delay: 0.1s;
 }
  .nav-mobile #menu-toggle {
    display: none;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button .icon-close, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-close {
    display: block;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button .icon-open, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-open {
    display: none;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button:after, .nav-mobile #menu-toggle:checked ~ .menu-button:after {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
 }
  .nav-mobile #menu-toggle.active ~ .menu-sidebar, .nav-mobile #menu-toggle:checked ~ .menu-sidebar {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
 }
  .nav-mobile .menu-container {
    width: 65px;
    float: left;
    cursor: pointer;
    position: absolute;
 }
  .nav-mobile .menu-container .menu-sidebar {
    left: 0;
    box-shadow: 5px 0 5px -5px #333;
    display: block;
    width: 65vw;
    bottom: 0;
    background: #343A40;
    color: #333;
    position: fixed;
    transform: translateX(-405px);
    transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
    top: 45px;
    z-index: 2;
    list-style-type: none;
    padding: 0;
    max-width: 400px;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow {
    position: absolute;
    line-height: 50px;
    font-size: 32px;
    color: #555;
    top: 0;
    z-index: 0;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow.left {
    left: 25px;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow.right {
    right: 25px;
 }
  .nav-mobile .menu-container .menu-sidebar li {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    text-align: left;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
 }
  .nav-mobile .menu-container .menu-sidebar li:hover {
    background: #2C5364;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    background: white;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0, 0, 0.3, 1);
    border-left: 1px solid #ccc;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 2;
    max-width: 400px;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub li {
    overflow: hidden;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub .menu-sub-title {
    padding-left: 50px;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-toggle {
    display: none;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-toggle.active ~ .menu-sub, .nav-mobile .menu-container .menu-sidebar li .submenu-toggle:checked ~ .menu-sub {
    width: 65vw;
    visibility: visible;
    z-index: 1;
    transition: width 0.35s cubic-bezier(0, 0, 0.3, 1);
 }
  
  }

