.card-header{
    background: #F7FAFC;
}

.cardbottom-bg{
    background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}

.cardloginbody{
 background: #F7FAFC;
}

.loginbtnbg{
border-radius: 50px;
    background: #292961;
padding: 10px;
color: #fff;
}
.loginbtnbg:hover{
    border-radius: 50px;
        background: #292961;
    padding: 10px;
    color: #fff;
    }

.center-div
{
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 400px;
  height: 300px; 
  border-radius: 3px;
  padding: 20px;
}



.input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .usericon {
    padding: 15px;
    background: #292961;
    color: white;
    min-width: 50px;
    text-align: center;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }
  
  .input-field:focus {
    border: 2px solid #292961;
  }
  