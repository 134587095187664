/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

 
  
  #notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 15px;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 220px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Kanit', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 186px;
    font-weight: 200;
    margin: 0px;
    background: linear-gradient(130deg, #ffa34f, #ff6f68);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
  }
  
  .notfound h2 {
    font-family: 'Kanit', sans-serif;
    font-size: 33px;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
  }
  
  
  .notfound p {
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  
  .notfound a {
    font-family: 'Kanit', sans-serif;
    color: #ff6f68;
    font-weight: 200;
    text-decoration: none;
    border-bottom: 1px dashed #ff6f68;
    border-radius: 2px;
  }
  
  .notfound-social>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: #ff6f68;
    border: 1px solid #efefef;
    border-radius: 50%;
    margin: 3px;
    transition: 0.2s all;
  }
  .notfound-social>a:hover {
    color: #fff;
    background-color: #ff6f68;
    border-color: #ff6f68;
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      position: relative;
      height: 168px;
    }
  
    .notfound .notfound-404 h1 {
      font-size: 142px;
    }
  
    .notfound h2 {
      font-size: 22px;
    }
  }
  
.flashMessages{
    text-align: center;   
    position: fixed; 
    z-index: 1000;  
}

.message__box {
    z-index: 10000;
}
.card-header{
    background: #F7FAFC;
}

.cardbottom-bg{
    background: #0F2027;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}

.cardloginbody{
 background: #F7FAFC;
}

.loginbtnbg{
border-radius: 50px;
    background: #292961;
padding: 10px;
color: #fff;
}
.loginbtnbg:hover{
    border-radius: 50px;
        background: #292961;
    padding: 10px;
    color: #fff;
    }

.center-div
{
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 400px;
  height: 300px; 
  border-radius: 3px;
  padding: 20px;
}



.input-container { /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .usericon {
    padding: 15px;
    background: #292961;
    color: white;
    min-width: 50px;
    text-align: center;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }
  
  .input-field:focus {
    border: 2px solid #292961;
  }
  

.myguestnav {
    height: 6%;
    width: 100%;
    background: #0F2027;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    position: relative;
  }
  
  .myguestnav > .nav-header {
    display: inline;
  }
  
  .myguestnav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  
  .myguestnav > .nav-btn {
    display: none;
  }
  
  .myguestnav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
  }
  
  .myguestnav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }
  
  .myguestnav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .myguestnav > #nav-check {
    display: none;
  }
  
  @media (min-width:610px) {
    .nav-mobile {
      display: none;
    }
  }
  @media (max-width:600px) {
   
  .nav-mobile {
    background: #446cb3;
    color: #fff;
    padding: 0;
    top: 0;
    position:absolute;
    cursor: auto;
    font-size: 18px;
    list-style-type: none;
    box-shadow: 0 5px 5px -5px #333;
 }
  .nav-mobile:after {
    content: "";
    display: table;
    clear: both;
 }
  .nav-mobile svg {
    background: #343A40;
    height: 45px;
    width: 65px;
    padding: 9px;
    left: 0;
    position: fixed;
 }
  .nav-mobile svg path {
    fill: #fff;
 }
  .nav-mobile svg.icon-close {
    display: none;
    padding: 15px;
 }
  .nav-mobile li {
    width: 100%;
    height: 45px;
    line-height: 46px;
    text-align: center;
    float: left;
 }
  .nav-mobile li .mlink {
    display: block;
    color: #fff;
    width: 100%;
    height: 100%;
    text-decoration: none;
 }
  .nav-mobile .menu-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    display: block;
 }
  .nav-mobile .menu-button:after {
    opacity: 0;
    top: 45px;
    content: "";
    width: 100vw;
    display: block;
    position: fixed;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
    transition-delay: 0.1s;
 }
  .nav-mobile #menu-toggle {
    display: none;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button .icon-close, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-close {
    display: block;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button .icon-open, .nav-mobile #menu-toggle:checked ~ .menu-button .icon-open {
    display: none;
 }
  .nav-mobile #menu-toggle.active ~ .menu-button:after, .nav-mobile #menu-toggle:checked ~ .menu-button:after {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
 }
  .nav-mobile #menu-toggle.active ~ .menu-sidebar, .nav-mobile #menu-toggle:checked ~ .menu-sidebar {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
 }
  .nav-mobile .menu-container {
    width: 65px;
    float: left;
    cursor: pointer;
    position: absolute;
 }
  .nav-mobile .menu-container .menu-sidebar {
    left: 0;
    box-shadow: 5px 0 5px -5px #333;
    display: block;
    width: 65vw;
    bottom: 0;
    background: #343A40;
    color: #333;
    position: fixed;
    transform: translateX(-405px);
    transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
    top: 45px;
    z-index: 2;
    list-style-type: none;
    padding: 0;
    max-width: 400px;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow {
    position: absolute;
    line-height: 50px;
    font-size: 32px;
    color: #555;
    top: 0;
    z-index: 0;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow.left {
    left: 25px;
 }
  .nav-mobile .menu-container .menu-sidebar .arrow.right {
    right: 25px;
 }
  .nav-mobile .menu-container .menu-sidebar li {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    text-align: left;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
 }
  .nav-mobile .menu-container .menu-sidebar li:hover {
    background: #2C5364;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    background: white;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0, 0, 0.3, 1);
    border-left: 1px solid #ccc;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 2;
    max-width: 400px;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub li {
    overflow: hidden;
 }
  .nav-mobile .menu-container .menu-sidebar li .menu-sub .menu-sub-title {
    padding-left: 50px;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-toggle {
    display: none;
 }
  .nav-mobile .menu-container .menu-sidebar li .submenu-toggle.active ~ .menu-sub, .nav-mobile .menu-container .menu-sidebar li .submenu-toggle:checked ~ .menu-sub {
    width: 65vw;
    visibility: visible;
    z-index: 1;
    transition: width 0.35s cubic-bezier(0, 0, 0.3, 1);
 }
  
  }


.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 3px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 5px;
}

.tabs {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tabs .tab-pane {
  margin-left: 20px;
}

.tabs h3 {
  font-size: 20px;
  margin-top: 10px;
}

.tabs a {
  font-size: 15px;
  font-family: OpenSans, sans-serif;
  font-weight: 700;
  color: #fff;
}

.sidetab li {
  background-color: #333;
  margin-top: 1px;
  text-align: center;
  height: 50px;
  width: auto;
  padding-top: 13px;
  border-radius: 3px;
}

.tabs li.active {
  background-color: #0085c3;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 7px;
    cursor: pointer;
    margin: 2px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .operationbtns {
    background-color: #b9bfc7;
    color: #000000;
    padding: 12px;
    cursor: pointer;
    margin: 10px;
    font-size: 12px;
    border-radius: 20px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}

.inclusionSortDropDown {
  /* position: relative;
  padding-right: 150px;
  z-index: 10;
  */

  z-index: 10;
  width: 200px;
  right: 0;
  position: absolute;
  padding-right: 18px;
}

.inclusionSortDropDown .generateCourseBtn {
  position: absolute;
  top: 0;
  right: 0;
}


