.inclusionSortDropDown {
  /* position: relative;
  padding-right: 150px;
  z-index: 10;
  */

  z-index: 10;
  width: 200px;
  right: 0;
  position: absolute;
  padding-right: 18px;
}

.inclusionSortDropDown .generateCourseBtn {
  position: absolute;
  top: 0;
  right: 0;
}
